import { h } from 'hyperapp';
import './index.less';
import Header from './components/header/view.jsx';
import Topics from './components/topics/view.jsx';
import Items from './components/items/view.jsx';
import { ArticleItem, ArticleBoxItem } from '~/components/list-item/view.jsx';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-index",
  class: "mdui-container"
}, h(Header, {
  title: "\u63A8\u8350\u56FE\u96C6",
  url: "/topics#recommended"
}), h(Topics, {
  state: state,
  actions: actions
}), h("div", {
  class: "items-wrapper"
}, h(Header, {
  title: "最新图片素材",
  url: "/articles"
}), state.articles_recent_data.map(article => h(ArticleBoxItem, {
  article: article,
  actions: actions
}))), h("div", {
  class: "items-wrapper"
}, h(Header, {
  title: "最近热门图片素材",
  url: "/articles#popular"
}), state.articles_popular_data.map(article => h(ArticleBoxItem, {
  article: article,
  actions: actions
}))), h("div", {
  class: "items-wrapper"
}, h(Items, {
  title: "\u6700\u8FD1\u66F4\u65B0\u63D0\u95EE",
  items: state.questions_recent_data,
  primaryKey: "question_id",
  dataName: "questions_recent_data",
  loading: state.questions_recent_loading,
  url: "/questions",
  actions: actions
}), h(Items, {
  title: "\u6700\u8FD1\u70ED\u95E8\u63D0\u95EE",
  items: state.questions_popular_data,
  primaryKey: "question_id",
  dataName: "questions_popular_data",
  loading: state.questions_popular_loading,
  url: "/questions#popular",
  actions: actions
}))));