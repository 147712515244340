import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Editor from '~/components/editor/view.jsx';
import { ArticleItem, ArticleBoxItem } from '~/components/list-item/view.jsx';
import TopicSelector from '~/components/editor/components/topic-selector/view.jsx';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-articles",
  class: "mdui-container"
}, state.tabs.map(tabName => {
  const isEmpty = !state[`${tabName}_loading`] && !state[`${tabName}_data`].length && state[`${tabName}_pagination`];
  const isLoaded = !state[`${tabName}_loading`] && state[`${tabName}_pagination`] && state[`${tabName}_pagination`].page === state[`${tabName}_pagination`].pages;
  const isLoading = state[`${tabName}_loading`];
  return () => h("div", {
    id: tabName
  }, state[`${tabName}_data`].length ? h("div", {
    class: "items-wrapper"
  }, state[`${tabName}_data`].map(article => h(ArticleBoxItem, {
    article: article,
    last_visit_id: state.last_visit_id,
    tabName: tabName,
    actions: actions
  }))) : null, tabName === 'recent' ? h(Empty, {
    show: isEmpty,
    title: "\u5C1A\u672A\u53D1\u5E03\u4EFB\u4F55\u56FE\u7247\u7D20\u6750",
    description: "\u6B64\u5904\u4F1A\u663E\u793A\u6700\u8FD1\u66F4\u65B0\u7684\u56FE\u7247\u7D20\u6750",
    action: () => {
      actions.editorOpen();
    },
    action_text: "\u4E0A\u4F20\u56FE\u7247\u7D20\u6750"
  }) : null, tabName === 'popular' ? h(Empty, {
    show: isEmpty,
    title: "\u6CA1\u6709\u8FD1\u671F\u70ED\u95E8\u56FE\u7247\u7D20\u6750",
    description: "\u6B64\u5904\u4F1A\u663E\u793A\u8FD1\u671F\u6700\u53D7\u6B22\u8FCE\u7684\u56FE\u7247\u7D20\u6750",
    action: () => {
      actions.toRecent();
    },
    action_text: "\u67E5\u770B\u6700\u65B0\u56FE\u7247\u7D20\u6750"
  }) : null, tabName === 'following' ? h(Empty, {
    show: isEmpty,
    title: "\u5C1A\u672A\u5173\u6CE8\u4EFB\u4F55\u56FE\u7247\u7D20\u6750",
    description: "\u5173\u6CE8\u56FE\u7247\u7D20\u6750\u540E\uFF0C\u76F8\u5E94\u56FE\u7247\u7D20\u6750\u5C31\u4F1A\u663E\u793A\u5728\u6B64\u5904",
    action: () => {
      actions.toPopular();
    },
    action_text: "\u67E5\u770B\u70ED\u95E8\u56FE\u7247\u7D20\u6750"
  }) : null, h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  }));
})));