export default {
  showImageModal: imageUrl => state => Object.assign({}, state, {
    modalOpen: true,
    imageUrl
  }),
  hideImageModal: state => Object.assign({}, state, {
    modalOpen: false
  }),
  downloadImage: () => state => {
    const link = document.createElement('a');

    if (state.imageUrl) {
      link.href = state.imageUrl;
      const filename = link.href.split('/').pop();
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}; // 如果有其他动作，也可以在这里定义