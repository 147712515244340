import { h } from 'hyperapp';
import cc from 'classcat';
import { Link } from 'hyperapp-router';
import { fullPath, isPathArticle, isPathArticles, isPathInbox, isPathIndex, isPathNotifications, isPathQuestion, isPathQuestions, isPathTopic, isPathTopics, isPathUser, isPathUsers } from '~/utils/path';
import './index.less';
const {
  site_name,
  site_icp_beian,
  site_gongan_beian
} = window.G_OPTIONS;
const site_gongan_beian_code = site_gongan_beian ? site_gongan_beian.match(/\d+/)[0] : '';

const Item = ({
  url,
  icon,
  title,
  active
}) => h(Link, {
  to: fullPath(url),
  class: cc(['mdui-list-item', 'mdui-ripple', {
    'mdui-list-item-active': active
  }])
}, h("i", {
  class: "mdui-list-item-icon mdui-icon material-icons"
}, icon), h("div", {
  class: "mdui-list-item-content"
}, title));

export default (({
  user,
  interviewee
}) => h("div", {
  class: "mc-drawer mdui-drawer"
}, h("div", {
  class: "mdui-list"
}, h(Item, {
  url: "/",
  icon: "home",
  title: "\u9996\u9875",
  active: isPathIndex()
}), h(Item, {
  url: "/topics",
  icon: "class",
  title: "\u7CBE\u9009\u56FE\u96C6",
  active: isPathTopics() || isPathTopic()
}), h(Item, {
  url: "/articles",
  icon: "description",
  title: "\u56FE\u7247\u7D20\u6750",
  active: isPathArticles() || isPathArticle()
}), h(Item, {
  url: "/questions",
  icon: "forum",
  title: "\u95EE\u7B54",
  active: isPathQuestions() || isPathQuestion()
}), h("div", {
  class: "mdui-divider"
}), user ? h(Item, {
  url: `/users/${user.user_id}`,
  icon: "account_circle",
  title: "\u4E2A\u4EBA\u8D44\u6599",
  active: isPathUser() && interviewee && user.user_id === interviewee.user_id
}) : null, h(Item, {
  url: "/users",
  icon: "people",
  title: "\u4EBA\u8109",
  active: isPathUsers() || isPathUser() && (!user || interviewee && user.user_id !== interviewee.user_id)
}), user ? h(Item, {
  url: "/notifications",
  icon: "notifications",
  title: "\u901A\u77E5",
  active: isPathNotifications()
}) : null), h("div", {
  class: "copyright"
}, site_icp_beian ? h("p", null, h("a", {
  href: "https://beian.miit.gov.cn/",
  target: "_blank"
}, site_icp_beian)) : null, site_gongan_beian ? h("p", null, h("a", {
  href: `http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${site_gongan_beian_code}`,
  target: "_blank"
}, site_gongan_beian)) : null, h("p", null, "\xA9 ", new Date().getFullYear(), " ", site_name), h("p", null, "Powered by", ' ', h("a", {
  href: "https://mdui.org",
  target: "_blank"
}, "MDUI"), ' ', "&", ' ', h("a", {
  href: "https://mdclub.org",
  target: "_blank"
}, "MDClub")))));