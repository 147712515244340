import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
export default (({
  show = false,
  title,
  description = '',
  action = false,
  action_text = ''
}) => h("div", {
  class: cc(['mc-empty', {
    'mdui-hidden': !show
  }]),
  key: "mc-empty"
}, h("div", {
  class: "title"
}, title), description ? h("div", {
  class: "description"
}, description) : null, action ? h("button", {
  class: "mdui-btn mdui-btn-raised mdui-ripple mdui-color-theme",
  onclick: () => {
    action();
  }
}, action_text) : null));