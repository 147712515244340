export default {
  // 图片素材ID
  article_id: 0,
  // 当前访问的图片素材信息
  article: null,
  // 是否正在加载提问数据
  loading: false,
  // 是否正在变更关注状态
  following_article: false,
  showModal: false,
  currentImage: null
};