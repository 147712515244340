import { h } from 'hyperapp';
import IconButton from '~/components/icon-button/view.jsx';
import "./index.less";

const Modal = ({
  isOpen,
  imageUrl,
  onClose,
  onDownload
}) => {
  const modalStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 999,
    textAlign: "center",
    paddingTop: "100px"
  };
  const imageStyle = {
    maxWidth: "90%",
    maxHeight: "90vh"
  };

  const handleModalClick = event => {
    // 检查点击的区域是否为图片区域
    if (event.target.tagName !== 'IMG') {
      onClose();
    }
  };

  return h("div", {
    style: modalStyle,
    onclick: handleModalClick
  }, h("img", {
    src: imageUrl,
    style: imageStyle
  }), h("div", {
    class: "button-container"
  }, h("button", {
    class: "button1",
    onclick: onClose
  }, "\u5173\u95ED\u7A97\u53E3"), h("button", {
    class: "button2",
    onclick: onDownload
  }, "\u4FDD\u5B58\u56FE\u7247")));
}; // ImageListFromHtml组件  


const ImageViewer = ({
  htmlContent,
  alt,
  state,
  actions
}) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const imgTags = doc.getElementsByTagName('img');
  const imageSrcs = Array.from(imgTags).map(img => img.getAttribute('src') || img.getAttribute('srcset'));
  return h("div", {
    class: "mdui-typo content"
  }, imageSrcs.map((src, index) => h("img", {
    class: "article-image",
    src: src,
    alt: alt,
    onclick: () => actions.showImageModal(src)
  })), h(Modal, {
    isOpen: state.modalOpen,
    imageUrl: state.imageUrl,
    onClose: actions.hideImageModal,
    onDownload: actions.downloadImage
  }));
};

export default ImageViewer;