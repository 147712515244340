import { h } from 'hyperapp';
import { richText, summaryText } from '~/utils/html';
import { emit } from '~/utils/pubsub';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import UserLine from '~/components/user-line/view.jsx';
import Follow from '~/components/follow/view.jsx';
import Vote from '~/components/vote/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
import TopicsBar from '~/components/topics-bar/view.jsx';
import Comments from '~/components/comments/page.jsx';
import Nav from '~/components/nav/view.jsx';
import ImageViewer from '~/components/image-viewer/view.jsx';
export default ((state, actions) => ({
  match
}) => {
  const article_id = parseInt(match.params.article_id, 10);
  const {
    article,
    loading
  } = state;
  return h("div", {
    oncreate: () => actions.onCreate({
      article_id
    }),
    key: match.url,
    id: "page-article",
    class: "mdui-container"
  }, h(Nav, {
    path: "/articles"
  }), h("div", {
    class: "mdui-card mdui-card-shadow article"
  }, article ? [h("h1", {
    class: "title",
    oncreate: summaryText(article.title),
    onupdate: summaryText(article.title),
    key: "0"
  }), h(UserLine, {
    actions: actions,
    user: article.relationships.user,
    time: article.create_time,
    dataName: "article",
    key: "1"
  }), h(ImageViewer, {
    htmlContent: article.content_rendered,
    alt: article.title,
    state: state,
    actions: actions,
    key: "2"
  }), article.relationships.topics.length ? h(TopicsBar, {
    topics: article.relationships.topics,
    key: "3"
  }) : null, h("div", {
    class: "actions",
    key: "4"
  }, h(Vote, {
    actions: actions,
    item: article,
    type: "article"
  }), h(Follow, {
    item: article,
    type: "article",
    actions: actions
  }), h("div", {
    class: "flex-grow"
  }), h(OptionsButton, {
    type: "article",
    item: article,
    extraOptions: [{
      name: `查看 ${article.follower_count} 位关注者`,
      onClick: () => {
        emit('users_dialog_open', {
          type: 'article_followers',
          id: article_id
        });
      }
    }]
  }))] : null, h(Loading, {
    show: loading
  })), h(Comments, {
    commentable_type: "article",
    commentable_id: article_id
  }));
});