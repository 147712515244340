import { h } from 'hyperapp';
import './index.less';
import cc from 'classcat';
import ListHeader from '~/components/list-header/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Tab from '~/components/tab/view.jsx';
import { QuestionItem, ArticleItem, ArticleBoxItem } from '~/components/list-item/view.jsx';
export default (({
  state,
  actions
}) => h("div", {
  class: cc(["contexts" // "mdui-card"
  ])
}, h(Tab, {
  items: [{
    name: '图片素材',
    hash: 'articles',
    count: state.topic ? state.topic.article_count : 0
  }, {
    name: '提问',
    hash: 'questions',
    count: state.topic ? state.topic.question_count : 0
  }]
}), state.tabs.map(tabName => {
  const pagination = state[`${tabName}_pagination`];
  const data = state[`${tabName}_data`];
  const order = state[`${tabName}_order`];
  const isLoading = state[`${tabName}_loading`];
  const isEmpty = !isLoading && !data.length && pagination;
  const isLoaded = !isLoading && pagination && pagination.page === pagination.pages;
  return () => h("div", {
    id: tabName
  }, tabName === 'articles' ? h(ListHeader, {
    show: !isEmpty,
    title: `共 ${pagination ? pagination.total : 0} 篇图片素材`,
    disabled: isLoading,
    currentOrder: order,
    key: tabName,
    orders: [{
      order: '-update_time',
      name: '更新时间（从晚到早）'
    }, {
      order: 'update_time',
      name: '更新时间（从早到晚）'
    }, {
      order: '-vote_count',
      name: '最热门'
    }],
    onChangeOrder: actions.changeOrder
  }) : null, tabName === 'questions' ? h(ListHeader, {
    show: !isEmpty,
    title: `共 ${pagination ? pagination.total : 0} 个提问`,
    disabled: isLoading,
    currentOrder: order,
    key: tabName,
    orders: [{
      order: '-update_time',
      name: '更新时间（从晚到早）'
    }, {
      order: 'update_time',
      name: '更新时间（从早到晚）'
    }],
    onChangeOrder: actions.changeOrder
  }) : null, data.length ? [tabName === 'articles' ? h("div", {
    class: "items-wrapper",
    key: "0"
  }, data.map(context => h(ArticleBoxItem, {
    article: context,
    last_visit_id: state.last_visit_article_id,
    tabName: tabName,
    actions: actions
  }))) : null, tabName === 'questions' ? h("div", {
    class: "item-list",
    key: "1"
  }, data.map(context => h(QuestionItem, {
    question: context,
    last_visit_id: state.last_visit_question_id,
    tabName: tabName,
    actions: actions
  }))) : null] : null, h(Empty, {
    show: isEmpty,
    title: `该图集下没有${tabName === 'questions' ? '提问' : '图片素材'}`
  }), h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  }));
})));