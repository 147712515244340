export default {
  /**
   * 链接
   */
  url: '',

  /**
   * 描述
   */
  title: ''
};